// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import ProgressBar from "react-bootstrap/ProgressBar"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const LoadingBar = () => {
  const status = useSelector((state) => state.embedQuicksight.status)
  const [className, setClassName] = useState("d-none")
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    switch (status) {
    case "init":
      setClassName("")
      setPercent(13)
      setTimeout(() => {
        setPercent(33)
      }, 100)
      break
    case "loading":
      setClassName("")
      setPercent(55)
      break
    case "succeeded":
      setPercent(100)
      setTimeout(() => {
        setClassName("d-none")
      }, 1000)
      break
    default:
      // reset
      setClassName("d-none")
      setPercent(0)
    }
  }, [status])

  return (
    <ProgressBar className={className} now={percent} />
  )
}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default LoadingBar
