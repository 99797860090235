import { useEffect, useState } from "react"

import "./labLink.css"

const LabLink = ({to, testUrl}) => {

  const defaultClass = "lh-lg px-3 nav-link"

  const [navLink, setNavLink] = useState(null)
  const [className, setClassName] = useState(`${defaultClass} nav-link-pending`)
  const [isActive, setIsActive] = useState(false)

  const [anchor, setAnchor] = useState(null)

  useEffect(() => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", testUrl, true);
    xhr.timeout = 3000;

    // success handler
    xhr.onload = () => {
      setIsActive(true)
    };

    const onFail = () => {
      console.log(`Error accessing Lab (${testUrl}). Please contact support@tradefeedr.com`)
      setClassName(`${defaultClass} nav-link-disabled`)
      setIsActive(false)
    }
    // fail handler
    xhr.ontimeout = onFail
    xhr.onerror = onFail

    try {
      xhr.send(null);
    } catch(e) {
      // nothing to be done
    }
  }, [testUrl])

  useEffect(() => {
    if (isActive) {
      setAnchor(
        <a
          className="lablink"
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          Lab
        </a>
      )
    } else {
      setAnchor(
        <span title="Error accessing Lab. Please contact support@tradefeedr.com">
          Lab
        </span>
      )
    }
  }, [isActive, to])

  useEffect(() => {
    setNavLink(
      <div className={className}>
        {anchor}
      </div>
    )
  }, [anchor, className])

  return navLink
}

export default LabLink
