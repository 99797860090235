// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { Auth } from "aws-amplify"

import { getConfig } from "../config/config"

// ----------------------------------------------------------------------------
// Config / Settings / State
// ----------------------------------------------------------------------------

const config = getConfig()

// ----------------------------------------------------------------------------
// Private Functions / Classes
// ----------------------------------------------------------------------------

export const getAwsAuthenticatedUser = async (getIdTokenClaims) => {

  const claims = await getIdTokenClaims()
  const { email, name, exp } = claims
  const idToken = claims.__raw

  let user
  try {
    user = await Auth.currentAuthenticatedUser()
    await Auth.currentCredentials()
  } catch (err) {
    // per https://docs.amplify.aws/lib/auth/advanced/q/platform/js/#federate-with-auth0
    await Auth.federatedSignIn(
      config.domain,
      { token: idToken, expires_at: exp * 1000 },
      { name, email },
    )
    user = await Auth.currentAuthenticatedUser()
  }
  return user

}

export default getAwsAuthenticatedUser
