// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { useAuth0 } from "@auth0/auth0-react"
import { Auth } from "aws-amplify"

import ErrorCard from "../components/errorCard"

import "./pages.css"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const Error = ({message}) => {

  const { logout } = useAuth0()

  function logoutWrapper() {
      Auth.signOut()
      logout({ returnTo: window.location.origin })
  }

  return (
    <div className="Page">
      <header className="Page-header">
        <ErrorCard message={message} logoutWrapper={logoutWrapper} />
      </header>
    </div>
  )

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default Error
