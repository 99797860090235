// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import config from "./config.json"

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export const getConfig = () => (
  {
    domain: config.domain,
    clientId: config.clientId,
    api: config.api,
    endpoint: config.endpoint,
    cookieEndpoint: config.cookieEndpoint,
    version: config.version,
  }
)
