// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Auth } from "aws-amplify"

import "./pages.css"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const Logout = () => {

  const { logout } = useAuth0()

  useEffect(() => {
      setTimeout(() => {
        Auth.signOut()
        logout({ returnTo: window.location.origin })
      }, 1000)
  })

  return (
    <>
      <div className="Page">
        <header className="Page-header">
          <p>
            Logout
          </p>
        </header>
      </div>
    </>
  )

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default Logout
