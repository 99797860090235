// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { configureStore } from "@reduxjs/toolkit"

import embedQuicksightReducer from "../features/embedQuicksight"

// ----------------------------------------------------------------------------
// Config / Settings / State
// ----------------------------------------------------------------------------

export const store = configureStore({
  reducer: {
    embedQuicksight: embedQuicksightReducer,
  },
})

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------
