// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import Container from "react-bootstrap/Container"
import NavDropdown from "react-bootstrap/NavDropdown"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"

import { NavLink } from "react-router-dom"

import LogoutButton from "./logoutButton"

import logo from "../logo.svg"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const MenuHeader = (props) => {

  return (
    <>
    <Container className="menu border-bottom px-0 py-0" fluid>
      <Navbar className="py-0" expand="lg" variant="light" >
        <Container className="px-5px" fluid>
          <NavLink to="/">
            <img src={logo} className="App-logo" alt="logo" />
          </NavLink>
          <Navbar id="basic-navbar-nav" >
            <Nav className="ms-auto">
            {props.authenticated && (
              <>
                {props.children}
                <span className="lh-lg ps-1 pe-3 nav-link" >|</span>
                <NavDropdown
                  id="nav-dropdown-dark-example"
                  title={props.userName}
                  menuVariant="light"
                  align="end"
                  className="mt-1 text-secondary"
                  >
                  <NavDropdown.ItemText className="text-secondary">
                    {props.userName}
                  </NavDropdown.ItemText>
                  <NavDropdown.Divider />
                  <NavDropdown.ItemText className="text-secondary">
                    <LogoutButton />
                  </NavDropdown.ItemText>
                </NavDropdown>
              </>
            )}
            </Nav>
          </Navbar>
        </Container>
      </Navbar>
    </Container>
    </>
  )
}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default MenuHeader
