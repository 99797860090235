// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { API } from "aws-amplify"

import { getAwsAuthenticatedUser } from "../auth/getAwsAuthenticatedUser"

import { getConfig } from "../config/config"

// ----------------------------------------------------------------------------
// Config / Settings / State
// ----------------------------------------------------------------------------

const config = getConfig()

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

export const getCloudFrontCookies = async (getIdTokenClaims, isLogout) => {

  const user = await getAwsAuthenticatedUser(getIdTokenClaims)
  const args = {
    headers: { "Content-Type": "application/json" },
    body: {
      token: user.token,
      logout: isLogout,
    },
    response: true,
    withCredentials: true,
  }

  const response = await API.post(config.api, config.cookieEndpoint, args)
  return response.data
}
