// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import loading from "../loading.svg"

import "./pages.css"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const Loading = () => {

  return (
    <div className="Page">
      <header className="Page-header">
        <img src={loading} className="App-loading" alt="logo" />
      </header>
    </div>
  )

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default Loading
