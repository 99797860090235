// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import React from "react"
import ReactDOM from "react-dom/client"

import { Amplify, API } from "aws-amplify"
import awsExports from "./aws-exports"

import { BrowserRouter } from "react-router-dom"

import Auth0ProviderTradefeedr from "./auth/auth0ProviderTradefeedr"

import { Provider } from "react-redux"
import { store } from "./store/store"

import App from "./App"

import "bootstrap/dist/css/bootstrap.css"
import "./index.css"

// ----------------------------------------------------------------------------
// Config / Settings / State
// ----------------------------------------------------------------------------

Amplify.configure(awsExports)

const amplifyEndpoint = API._restApi._options.endpoints[0]
Amplify.configure({
  aws_cloud_logic_custom: [
    {
      ...amplifyEndpoint,
      endpoint: process.env.REACT_APP_API_URL || amplifyEndpoint.endpoint,
    },
  ]
})

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderTradefeedr>
        <Provider store={store}>
          <App />
        </Provider>
      </Auth0ProviderTradefeedr>
    </BrowserRouter>
  </React.StrictMode>
)
