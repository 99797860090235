// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------
import { useCallback } from "react"

import { useAuth0 } from "@auth0/auth0-react"
import { Auth } from "aws-amplify"

import Button from "react-bootstrap/Button"

import { getCloudFrontCookies } from "../features/getCloudFrontCookies"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const LogoutButton = () => {

  const { logout, getIdTokenClaims, isAuthenticated } = useAuth0()

  const doLogout = useCallback(() => {
    Auth.signOut()
    logout({ returnTo: window.location.origin })
  }, [logout])

  const clearCookiesAndLogout = useCallback(() => {
    if (isAuthenticated) {
      getCloudFrontCookies(getIdTokenClaims, true)
        .then(cookies => {doLogout()})
        .catch(err => {console.log(err); doLogout()})
    } else {
      doLogout()
    }
  }, [getIdTokenClaims, isAuthenticated, doLogout])

  return (
    <Button
      variant="primary"
      className="rounded-0 w-100"
      onClick={clearCookiesAndLogout}
    >
      Log Out
    </Button>
  )
}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default LogoutButton
