// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import Button from "react-bootstrap/Button"
import { useAuth0 } from "@auth0/auth0-react"
import { auth0LoginWithRedirect } from "../features/auth0LoginWithRedirect"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  const loginWrapper = () => { auth0LoginWithRedirect(loginWithRedirect) }

  return (
    <Button
      variant="primary" className="LoginButton"
      onClick={() => loginWrapper()}
    >
      Log In
    </Button>
  )
}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default LoginButton
