// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import LoginButton from "../components/loginButton"

import logo from "../logo.svg"

import { getConfig } from "../config/config"

import "./pages.css"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const Login = () => {

  const config = getConfig()

  return (
    <div className="Page">
      <header className="Page-header">
        <img src={logo} className="Logo" alt="Tradefeedr" />
        <span className="Version">Analytics {config.version}</span>
        <LoginButton />
      </header>
    </div>
  )

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default Login
