// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"

import { useAuth0 } from "@auth0/auth0-react"

import { getAwsAuthenticatedUser } from "../auth/getAwsAuthenticatedUser"

import auth0LoginWithRedirect from "../features/auth0LoginWithRedirect"
import { getQuickSightUrl } from "../features/embedQuicksight"

import LoadingBar from "../components/loadingBar"
import Error from "./error"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const Analytics = () => {

  const { isLoading, isAuthenticated, getIdTokenClaims, loginWithRedirect } = useAuth0()

  const dispatch = useDispatch()
  const status = useSelector((state) => state.embedQuicksight.status)
  const loadedDashboardId =  useSelector((state) => state.embedQuicksight.dashboardId)
  const [searchParams, ] = useSearchParams()

  const [dashboardId, setDashboardId] = useState("")

  const [failed, setFailed] = useState(false)
  const [message, setMessage] = useState("Error loading Analytics")

  useEffect(() => {
    const error = searchParams.get("error") || ""
    if (error !== "") {
      setMessage(searchParams.get("error_description") || "Error occurred during login")
      setFailed(true)
    }
  }, [searchParams])

  useEffect(() => {
    if (isLoading || failed) {
      return
    }
    if (!isAuthenticated) {
      auth0LoginWithRedirect(loginWithRedirect)
    }
  }, [isLoading, failed, isAuthenticated, searchParams, loginWithRedirect])

  useEffect(() => {
    setDashboardId(searchParams.get("dashboard_id") || "")
  }, [dashboardId, searchParams])

  useEffect(() => {
    if (isLoading || !isAuthenticated || failed) {
      return
    }
    if (status === "init" || (status === "succeeded" && dashboardId !== loadedDashboardId)) {
      getAwsAuthenticatedUser(getIdTokenClaims)
        .then(user => {dispatch(getQuickSightUrl({user, dashboardId}))})
        .catch(err => {console.log(err); setFailed(true)})
    }
  }, [isLoading, isAuthenticated, failed, status, dashboardId, loadedDashboardId, getIdTokenClaims, dispatch])

  return failed
    ? <Error message={message} />
    : <LoadingBar />

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default Analytics
