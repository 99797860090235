// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { Auth0Provider } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"

import { getConfig } from "../config/config"

// ----------------------------------------------------------------------------
// Config / Settings / State
// ----------------------------------------------------------------------------

const config = getConfig()

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const Auth0ProviderTradefeedr = ({ children }) => {

  const navigate = useNavigate()

  const onRedirectCallback = (state) => {
    try {
      const appState = JSON.parse(state)
      navigate({
        pathname: appState.pathname,
        search: appState.search,
      })
    } catch(err) {
      console.log("onRedirectCallback failed", err)
    }
  }

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      cacheLocation="localstorage"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokensFallback={false}
      authorizeTimeoutInSeconds={5}
    >
      {children}
    </Auth0Provider>
  )

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default Auth0ProviderTradefeedr
