// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useAuth0 } from "@auth0/auth0-react"

import { getCloudFrontCookies } from "../features/getCloudFrontCookies"
import auth0LoginWithRedirect from "../features/auth0LoginWithRedirect"

import Error from "./error"
import Loading from "./loading"

import "./pages.css"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const Docs = ({iframeSrc}) => {

  const { isLoading, isAuthenticated, user, getIdTokenClaims, loginWithRedirect } = useAuth0()

  const [page, setPage] = useState(null)

  const [ready, setReady] = useState(false)
  const [failed, setFailed] = useState(false)
  const [message, setMessage] = useState("Error loading documentation")

  const [searchParams, ] = useSearchParams()

  useEffect(() => {
    const error = searchParams.get("error")
    if (error) {
      setMessage(searchParams.get("error_description") || "Error occurred during login")
      setFailed(true)
    }
  }, [searchParams, message])

  useEffect(() => {
    if (isLoading || failed) {
      return
    }
    if (!isAuthenticated) {
      auth0LoginWithRedirect(loginWithRedirect)
    }
  }, [isLoading, failed, isAuthenticated, loginWithRedirect])

  useEffect(() => {
    if (!user) {
      return
    }
    if (failed) {
      // don't try again
      return
    }
    getCloudFrontCookies(getIdTokenClaims, false)
      .then(cookies => {setReady(true)})
      .catch(err => {console.log(err); setFailed(true)})
  }, [user, getIdTokenClaims, failed])

  useEffect(() => {
    if (failed) {
      setPage(<Error message={message} />)
      return
    }
    if (!ready) {
      setPage(<Loading />)
      return
    }
    const docs = (
      <div className="Page">
        <div className="embed">
          <iframe
            title="Tradefeedr Documentation"
            src={iframeSrc}
          />
        </div>
      </div>
    )
    setPage(docs)
  }, [failed, ready, message, iframeSrc])

  return page

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default Docs
