// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const ErrorCard = (props) => {

  return (
    <Container className={"px-0 my-0 embed "} fluid>
      <Row>
        <Col md={{ span: 4, offset: 4 }} className="">
          <Card className="my-5 px-2 w-100 border shadow-sm" >
            <Card.Body>
              <Card.Title className="">Tradefeedr</Card.Title>
              <Card.Text className="mt-4 text-danger">
                {props.message}
              </Card.Text>
              <Card.Text className="mt-2 mb-1 text-danger">
                Please contact <span className="text-dark">support@tradefeedr.com</span>
              </Card.Text>
              </Card.Body>
              <Card.Body>
              <Card.Link onClick={props.logoutWrapper}
                className="cursor-pointer float-end p-1 px-3 border text-muted border-2 text-decoration-none"
              >
                Close
              </Card.Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default ErrorCard
