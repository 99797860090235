// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { API } from "aws-amplify"

import { getConfig } from "../config/config"

// ----------------------------------------------------------------------------
// Config / Settings / State
// ----------------------------------------------------------------------------

const config = getConfig()

const initialState = {
  status: "init",
  url: null,
  ready: null,
  error: null,
  message: null,
  dashboardId: "",
}

// ----------------------------------------------------------------------------
// Private Functions / Classes
// ----------------------------------------------------------------------------

const updateStatusPending = (state, action) => {
  state.status = "loading"
  state.error = null
  state.message = null
}

const updateStatusFulfilled = (state, action) => {
  if (action.payload.error === 1001) {
    state.status = "failed"
    state.ready = false
    state.error = 1001
    state.message = action.payload.message
  } else {
    state.status = "succeeded"
    state.url = action.payload.url
    state.ready = false
    state.error = null
    state.message = null
    state.dashboardId = action.payload.dashboardId
  }
}

const updateStatusRejected = (state, action) => {
  state.status = "failed"
  state.error = 1000
  state.message = action.error.message
}

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

export const getQuickSightUrl = createAsyncThunk("embedQuicksight/getQuickSightUrl", async (payload) => {
  const {user, dashboardId} = payload
  const args = {
    headers: { "Content-Type": "application/json" },
    body: {
      token: user.token,
      dashboardId: dashboardId,
    },
    response: true,
  }

  const response = await API.post(config.api, config.endpoint, args)
  return {
    dashboardId,
    ...response.data,
  }
})

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const embedQuicksightSlice = createSlice({
  name: "embedQuicksight",
  initialState,
  reducers: {
    updateReady: (state, action) => {
      state.ready = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getQuickSightUrl.pending,  updateStatusPending)
      .addCase(getQuickSightUrl.fulfilled, updateStatusFulfilled)
      .addCase(getQuickSightUrl.rejected, updateStatusRejected)
  },
})

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export const { updateReady } = embedQuicksightSlice.actions
export default embedQuicksightSlice.reducer
