// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { useEffect, useState } from "react"
import { NavLink, Routes, Route, Navigate, useSearchParams } from "react-router-dom"
import { useAuth0, withAuth0 } from "@auth0/auth0-react"

import { useSelector } from "react-redux"

import MenuHeader from "./components/menuHeader"
import LabLink from "./components/labLink"

import Login from "./pages/login"
import Loading from "./pages/loading"

import Home from "./pages/home"
import Analytics from "./pages/analytics"
import Docs from "./pages/docs"

import Error from "./pages/error"
import Logout from "./pages/logout"

import QuickSight from "./components/quickSight"

import "./App.css"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

function App() {

  const { user, isAuthenticated, isLoading } = useAuth0()

  const status = useSelector((state) => state.embedQuicksight.status)
  const message = useSelector((state) => state.embedQuicksight.message)

  const [searchParams, ] = useSearchParams()

  const [userName, setUserName] = useState("undefined")
  const [rootElement, setRootElement] = useState(<Loading />)

  const platformUrl = process.env.REACT_APP_PLATFORM_URL || "https://platform.tradefeedr.com/"
  const platformTestUrl = process.env.REACT_APP_PLATFORM_TEST_URL || "https://platform.tradefeedr.com/_check"

  const documentationUrl = process.env.REACT_APP_DOCUMENTATION_URL || "https://docs.analytics.tradefeedr.com"

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (isAuthenticated) {
      setUserName(user.name)
    }
  }, [isLoading, isAuthenticated, user])

  useEffect(() => {
    const loginError = searchParams.get("error") || ""
    if (loginError !== "") {
      setRootElement(<Error message={searchParams.get("error_description") || "Error occurred during login"} />)
    } else if (status === "error") {
      setRootElement(<Error message={message} />)
    } else if (isLoading) {
      setRootElement(<Loading />)
    } else if (isAuthenticated) {
      setRootElement(<Home />)
    } else {
      setRootElement(<Login />)
    }
  }, [searchParams, status, message, isLoading, isAuthenticated])

  return (
    <>
      {isAuthenticated &&
        <MenuHeader userName={userName} authenticated={!isLoading && isAuthenticated}>
          <NavLink to="/analytics" className="lh-lg px-3 nav-link">
            Analytics
          </NavLink>
          <LabLink to={platformUrl} testUrl={platformTestUrl} />
          <NavLink to="/docs" className="lh-lg px-3 nav-link">
            Docs
          </NavLink>
        </MenuHeader>
      }

      <Routes>
        <Route path="/" element={rootElement} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/docs" element={<Docs iframeSrc={documentationUrl} />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/*" element={<Navigate replace to="/" />} />
        {isAuthenticated &&
          <Route path="/error" element={<Error message={message} />} />
        }
      </Routes>

      {isAuthenticated &&
        /* keep at parent level to avoid need to call lambda again */
        <QuickSight path="/analytics" />
      }
    </>
  )
}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default withAuth0(App)
