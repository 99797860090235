// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

export const auth0LoginWithRedirect = (loginWithRedirect) => {

  const appState = {
    pathname: window.location.pathname,
    search: window.location.search,
  }

  loginWithRedirect({appState: JSON.stringify(appState)})

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default auth0LoginWithRedirect
