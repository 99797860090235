// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useAuth0 } from "@auth0/auth0-react"

import LoadingBar from "../components/loadingBar"

import "./pages.css"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const Home = () => {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/analytics", { replace: true })
    }
  }, [isAuthenticated, navigate])

  return (
    <>
      {(isAuthenticated && window.location.path === "/" && <LoadingBar />)}
      <div className="Page">
        <header className="Page-header">
          <h2 className="home-heading text-center">Tradefeedr Analytics</h2>
        </header>
      </div>
    </>
  )

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default Home
