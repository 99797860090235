// ----------------------------------------------------------------------------
// Tradefeedr
// ----------------------------------------------------------------------------

import Container from "react-bootstrap/Container"

import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { updateReady } from "../features/embedQuicksight"

// ----------------------------------------------------------------------------
// Config / Settings / State
// ----------------------------------------------------------------------------

import { embedSession } from "amazon-quicksight-embedding-sdk"

// ----------------------------------------------------------------------------
// Public Functions / Classes
// ----------------------------------------------------------------------------

const QuickSight = ({path}) => {

  const location = useLocation()
  const embedUrl = useSelector((state) => state.embedQuicksight.url)
  const status = useSelector((state) => state.embedQuicksight.status)
  const dispatch = useDispatch()

  useEffect(() => {
    const onError = (err) => { console.error(err) }
    const containerDiv = document.getElementById("QuickSightContainer")
    containerDiv.innerHTML = ""
    if (embedUrl) {
      const options = {
        url: embedUrl,
        container: containerDiv,
        scrolling: "no",
        height: "AutoFit",
        loadingHeight: "700px",
        iframeResizeOnSheetChange: false,
        footerPaddingEnabled: true,
        printEnabled: true,
        resetDisabled: false,
        undoRedoDisabled: false,
      }
      const session = embedSession(options)
      session.on("error", onError)
      dispatch(updateReady(true))
    }
  }, [embedUrl, dispatch])

  return (
    <Container className={`bi px-0 my-0 embed ${location.pathname === path && status === "succeeded" ? "fadeIn" : "d-none"}`} fluid>
      <div id="QuickSightContainer"></div>
    </Container>
  )

}

// ----------------------------------------------------------------------------
// Init
// ----------------------------------------------------------------------------

export default QuickSight
